import React, { ReactNode, useCallback, useRef } from 'react';

import useEnrichedSourcesData from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import EnterpriseSearchContext, { EnterpriseSearchError } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import useEnterpriseSearchFallback
  from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchFallbackHook';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import { AnswerResponse, QuestionSource, SearchResultsResponse } from 'global/api/controller/EnterpriseSearchController';
import Api from 'global/api/platformApi';
import { useCustomAtomsFilters } from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { fixDaylightSavingTimeDifference } from 'utils/DateUtils';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

type Props = {
  children: ReactNode;
};

export default function EnterpriseSearchContextProvider({ children }: Props) {
  const [originalQuestion, setOriginalQuestion] = useMountedState('');
  const [loading, setLoading] = useMountedState(false);
  const [error, setError] = useMountedState<EnterpriseSearchError | null>(null);
  const { dispatchCustomAtomsFilters } = useCustomAtomsFilters();
  const [data, setData] = useMountedState<AnswerResponse | null>(null);
  const [
    searchResultsResponse, setSearchResultsResponse,
  ] = useMountedState<SearchResultsResponse | null>(null);
  const [isResultCardOpen, setEnterpriseResultCard] = useMountedState(data !== null);
  const { tenant } = useTenantContext();
  const abortControllerRef = useRef<AbortController | null>(null);

  const abortCurrentRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      // Make sure we continue on the next tick when the current request is aborted
      // for real.
      return new Promise((resolve) => {
        setTimeout(resolve, 0);
      });
    }
    return Promise.resolve();
  };

  const performEnterpriseSearch = async (
    tenantId: number,
    searchQuery: string,
    questionSource: QuestionSource,
    skipPromptCleaning: boolean,
  ) => {
    const response = await Api.EnterpriseSearch.enterpriseSearch(
      tenantId,
      searchQuery,
      questionSource,
      abortControllerRef.current?.signal,
      skipPromptCleaning,
    );
    if (response?.data) {
      if (response?.data.answer) {
        setData(response.data.answer);
        Analytics.trackEvent(
          AnalyticsEvent.ENTERPRISE_SEARCH_USED,
          { query: searchQuery, sessionId: response.data.answer.sessionId, location: 'Homepage' },
        );
      }
      setSearchResultsResponse(response.data.searchResults);
      updateDatesRangeFiltersBasedOnExtractedDatesRange(response.data.searchResults);
      Analytics.trackEvent(
        AnalyticsEvent.TERM_SEARCHED,
        {
          term: searchQuery,
          gotResults: !!response.data.searchResults
              && response.data.searchResults.topics.totalResultsCount > 0,
        },
      );
    } else {
      throw new Error('Error in Enterprise Search response');
    }
  };

  const updateDatesRangeFiltersBasedOnExtractedDatesRange = (
    searchResults: SearchResultsResponse,
  ) => {
    if (!searchResults.extractedDatesRange) {
      return;
    }

    const { startDate, endDate } = searchResults.extractedDatesRange;
    const from = fixDaylightSavingTimeDifference(new Date(startDate));
    const to = fixDaylightSavingTimeDifference(new Date(endDate));
    dispatchCustomAtomsFilters({
      type: 'SET_FILTERS',
      payload: { datesRange: { from, to } },
    });
  };

  const handleEnterpriseSearch = useCallback(async (
    searchQuery: string,
    questionSource: QuestionSource,
    skipPromptCleaning: boolean = false,
  ) => {
    await abortCurrentRequest();
    abortControllerRef.current = new AbortController();

    try {
      setLoading(true);
      setError(null);
      setData(null);
      setSearchResultsResponse(null);
      setEnterpriseResultCard(false);
      setOriginalQuestion(searchQuery);
      await performEnterpriseSearch(
        tenant.id,
        searchQuery,
        questionSource,
        skipPromptCleaning,
      );
    } catch (err) {
      if (abortControllerRef.current.signal.aborted) {
        setError('abort');
      } else {
        setError('network');
      }
    } finally {
      setLoading(false);
      setEnterpriseResultCard(true);
    }
  }, [tenant.id]);

  const handleAbortSearch = (searchQuery: string) => {
    DebuggerConsole.log('User aborted search result', { searchQuery });
    abortCurrentRequest();
    setEnterpriseResultCard(true);
    setError('abort');
    setLoading(false);
    setOriginalQuestion(searchQuery);
  };

  const dataWithFallback = useEnterpriseSearchFallback(data, searchResultsResponse, loading);
  const enrichedData = useEnrichedSourcesData(dataWithFallback);

  return (
    <EnterpriseSearchContext.Provider
      value={{
        originalQuestion,
        loading,
        hasError: error,
        data: enrichedData,
        searchResultsResponse: {
          value: searchResultsResponse,
          set: setSearchResultsResponse,
        },
        isResultCardOpen,
        handleEnterpriseSearch,
        handleAbortSearch,
        closeResultCard: () => setEnterpriseResultCard(false),
      }}
    >
      {children}
    </EnterpriseSearchContext.Provider>
  );
}
